import './header.css';
import Spline from '@splinetool/react-spline';

export default function Header(){


   return(
    <div className="Header" >
        <div className="Header-left">
           <div className="hlt anim-div">
              Oltre le parole
           </div>
           <div className="hls anim-div">
              il potere della creatività.
           </div>
           <div className="hlc anim-div">
              Sia che tu narri storie attraverso immagini in movimento o parole che danzano nell'aria, ogni creazione è un ponte verso nuove prospettive.
           </div>
        </div>
        <div className="spline">
            <spline-viewer loading-anim-type="spinner-small-dark" url="https://prod.spline.design/O3VNkBqUHtNhE1cV/scene.splinecode"></spline-viewer>
        </div>
         

    </div>




   );
}