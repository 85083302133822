import "./global.css"
export default function Video(){
  return(
    <div className="video">
       <div className="video_container">
        <div className="video-box">
        <iframe src = "https://www.youtube.com/embed/Ck06cCWla40?si=MaFEkRxhi6qzh5H8"></iframe>
        </div>
        </div>
    </div>
    
  )

}