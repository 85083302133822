import teenagers from "../Assets/slider-images/teen.gif"
import college from "../Assets/slider-images/college.gif"
import myc from "../Assets/slider-images/MYC.gif"
import marconi from "../Assets/images/gmarconi.png"
import "./slider.css"
import "./global.css"

export default function Header(){


    return(
        <div className="slider">
            <div className="list">
                <div className="item active">
                    <img src={myc} alt="Foto" />
                    <div className="content">
                        <p>categoria</p>
                        <h2 className="anim-div">Marconi's young communicators</h2>
                        <p>
                        Marconi's Young Communicators è la sezione dedicata agli studenti iscritti all'ITST G.Marconi di Campobasso. In basso sono presenti i bottoni per la consegna dei lavori.
                        </p>
                        {/* ADDON BOTTONI */}
                        <div className="dely-btt-block">
                        <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1LnJ2AD9wD8KyI0y4RLvqVScYW6vdLmCvP_-GCB9udtQ/viewform?ts=65b1f4df&edit_requested=true" className="btt-dely-link">STORYTELLING</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1_nWHTajuw0IWhK0NV37v4l0XfXjYhVfx4BY-S_iasRs/viewform?ts=65b1f540&edit_requested=true" className="btt-dely-link">CANZONE</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1DW7uKP4T0kIbhgSuNdlh-t-rsOvUbajmgT51HCLa9nA/viewform?ts=65b1f569&edit_requested=true" className="btt-dely-link">IMMAGINE</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1pC6Du6auQ1r-HNxZtQdlXujgAK1wXxTAWU5xc0YmTrU/viewform?ts=65b1f4b0&edit_requested=true" className="btt-dely-link">MARCONI</a>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={college} alt="Foto" />
                    <div className="content">
                        <p>categoria</p>
                        <h2 className="anim-div">Young communicators</h2>
                        <p>
                        Young communicators è la sezione dedicata agli studenti appartenenti alla scuola secondaria di II grado 'dal I al V anno'. In basso sono presenti i bottoni per la consegna dei lavori.
                        </p>
                        {/* ADDON BOTTONI */}
                        <div className="dely-btt-block">
                        <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1LnJ2AD9wD8KyI0y4RLvqVScYW6vdLmCvP_-GCB9udtQ/viewform?ts=65b1f4df&edit_requested=true" className="btt-dely-link">STORYTELLING</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1_nWHTajuw0IWhK0NV37v4l0XfXjYhVfx4BY-S_iasRs/viewform?ts=65b1f540&edit_requested=true" className="btt-dely-link">CANZONE</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1DW7uKP4T0kIbhgSuNdlh-t-rsOvUbajmgT51HCLa9nA/viewform?ts=65b1f569&edit_requested=true" className="btt-dely-link">IMMAGINE</a>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="special" className="item">
                    <img src={teenagers} alt="Foto" />
                    <div className="content">
                        <p>categoria</p>
                        <h2 className="anim-div">Creative teenagers</h2>
                        <p>
                        Creative Teenagers è la sezione dedicata agli studenti appartenenti alle classi III della scuola secondaria di I grado. In basso sono presenti i bottoni per la consegna dei lavori.
                        </p>
                        {/* ADDON BOTTONI */}
                        <div className="dely-btt-block">
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_po1xaM7T4X43kX44wan1YMRYUs4ZolakNMTVjgJ49iPNqA/viewform" className="btt-dely-link">STORYTELLING</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScRFbSrZwOhqRjf75I3gj3qJdv22OvuX6wqogHLnSILUkXoPQ/viewform" className="btt-dely-link">CANZONE</a>
                            </button>
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdIgs-7EkkovZlPOTnPwmomuEXR4gFDbYLTAfiJTr3FilGzNg/viewform" className="btt-dely-link">IMMAGINE</a>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={marconi} alt="Foto" />
                    <div className="content">
                        <p>categoria</p>
                        <h2 className="anim-div">Special Edition</h2>
                        <p>
                        In occasione del 150 anniversario di Guglielmo Marconi si è deciso di creare una sezione speciale per celebrare l'inventore italiano che ha aperto la strada alla comunicazione senza fili. In basso il bottone per consegnare i lavori
                        </p>
                        {/* ADDON BOTTONI */}
                        <div className="dely-btt-block">
                            <button className="btt-dely anim-trigger">
                                <a href="https://docs.google.com/forms/d/1pC6Du6auQ1r-HNxZtQdlXujgAK1wXxTAWU5xc0YmTrU/viewform?ts=65b1f4b0&edit_requested=true" className="btt-dely-link">CONSEGNA</a>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div className="arrows">
                <button id="prev">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8L10 12L14 16" stroke="rgba(255,255,255,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </button>
                <button id="next">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 8L14 12L10 16" stroke="rgba(255,255,255,0.95)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </button>
            </div>
            
            <div className="thumbnail">
                <div className="item active">
                    <img src={myc} alt="Foto" />
                    <div className="content">
                    Marconi's Young Communicators
                    </div>
                </div>
                <div className="item">
                    <img src={college} alt="Foto" />
                    <div className="content">
                        Young communicators
                    </div>
                </div>
                <div className="item">
                    <img src={teenagers} alt="Foto" />
                    <div className="content">
                        Creative teenagers
                    </div>
                </div>
                <div className="item">
                    <img src={marconi} alt="Foto" />
                    <div className="content">
                        Special edition
                    </div>
                </div>
            </div>
        </div>
        
 
 
    );
 }