
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import NavBar from './components/navbar.jsx';
import Header from './components/header.jsx'
import reportWebVitals from './reportWebVitals';
import Pages from './components/pages.jsx';
import Footer from './components/footer.jsx';
import Video from './components/video.jsx';
import Slider from './components/slider.jsx';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
      <NavBar />
      <Header />
      <Pages />
      <Slider />
      <Video />
      <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
